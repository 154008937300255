import axios from 'axios';
const welcome = {
  namespaced: true,
  state() {
    return {
      home: {
        currently: 0,
        fr: {
          text: [
            'Nous sommes ouvert tous les jours',
            'Ouverture le 30 mai 2024',
          ],
          btn: 'Découvrir',
        },
        en: {
          text: ['We are opened everyday', 'Opening may 30 2024'],
          btn: 'Discover',
        },
        ar: {
          text: ['مفتوح يوميا', 'الافتتاح 30 ماي 2024'],
          btn: 'اكتشف',
        },
      },
      prices: {
        fr: [
          {
            id: 0,
            name: 'Adultes',
            price: 55,
            imgUrl: require('@/assets/_K8A7963.jpeg'),
            icos: ` <i class="fas fa-female"></i>
          <i class="fas fa-male"></i>`,
            heights: {
              min: '+1.40m',
              max: '',
            },
          },
          {
            id: 1,
            name: 'Enfants',
            price: 45,
            imgUrl: require('@/assets/DSC_9881.jpeg'),
            icos: `<i class="fas fa-child"></i>`,
            heights: {
              min: '0.80m',
              max: '1.40m',
            },
          },
          /*{
            id: 2,
            name: 'Bébé',
            price: 0,
            imgUrl: require('@/assets/DSC_9881.jpeg'),
            icos: `<i class="fas fa-child"></i>`,
            heights: {
              min: '',
              max: '0.80m',
            },
          },*/
        ],
        en: [
          {
            id: 0,
            name: 'Adults',
            price: 55,
            imgUrl: require('@/assets/_K8A7963.jpeg'),
            icos: ` <i class="fas fa-female"></i>
          <i class="fas fa-male"></i>`,
            heights: {
              min: '+1.40m',
              max: '',
            },
          },
          {
            id: 1,
            name: 'Kids',
            price: 45,
            imgUrl: require('@/assets/DSC_9881.jpeg'),
            icos: `<i class="fas fa-child"></i>`,
            heights: {
              min: '0.80m',
              max: '1.40m',
            },
          },
        ],
        ar: [
          {
            id: 0,
            name: 'الكبار',
            price: 55,
            imgUrl: require('@/assets/_K8A7963.jpeg'),
            icos: ` <i class="fas fa-female"></i>
          <i class="fas fa-male"></i>`,
            heights: {
              min: '+1.40م',
              max: '',
            },
          },
          {
            id: 1,
            name: 'الأطفال',
            price: 45,
            imgUrl: require('@/assets/DSC_9881.jpeg'),
            icos: `<i class="fas fa-child"></i>`,
            heights: {
              min: '0.80م',
              max: '1.40م',
            },
          },
        ],
      },
      pricesText: {
        fr: {
          text: 'Les Tarifs/Tickets 2024',
          currency: 'DT/jour',
        },

        en: {
          text: '2024 ticket prices',
          currency: 'TD/day',
        },
        ar: {
          text: 'الأسعار/تذاكر 2024',
          currency: 'د.ت / يوم',
        },
      },
      status: {
        fr: ['Ouvert', 'Fermé'],
        en: ['Open', 'Closed'],
        ar: ['مفتوح', 'مغلق'],
      },
      statusText: {
        fr: ['Ouvert de 9h30 à 17h30', 'Ouverture le 30 mai 2024'],
        en: ['Opened from 9:30AM to 5:30PM', 'Opening may 30th 2024'],
        ar: ['مفتوح من 9:30 الى 17:30', 'الافتتاح 30 ماي 2024'],
      },
      opDate: [2024, 4, 30],
      clDate: [2024, 8, 29],
    };
  },
  mutations: {
    setStatus(state, value) {
      state.status = value;
    },
    setPrices(state, value) {
      state.prices = value;
    },
  },
  getters: {
    getPrices(state, getters, rootState, rootGetters) {
      return state.prices[rootGetters['lang/getCurrentLang']];
    },
    getPricesText(state, getters, rootState, rootGetters) {
      return state.pricesText[rootGetters['lang/getCurrentLang']];
    },
    getStatus(state, getters, rootState, rootGetters) {
      return state.status[rootGetters['lang/getCurrentLang']];
    },
    getStatusText(state, getters, rootState, rootGetters) {
      return state.statusText[rootGetters['lang/getCurrentLang']];
    },
    getHome(state, getters, rootState, rootGetters) {
      return state.home[rootGetters['lang/getCurrentLang']];
    },
  },
  actions: {
    getStatus({ state, commit, rootGetters }) {
      if (state.status === null) {
        axios
          .post('/lienVersDonnée', {
            currentLang: rootGetters['lang/getCurrentLang'],
          })
          /*
          retourne status : "Etat deja traduit depuis la base"
        */
          .then((response) => {
            // dans la reponse on doit avoir status qui changera la valeur actuelle.
            commit('setStatus', response.status);
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },
    getPrices({ state, commit, rootGetters }) {
      if (state.price === null) {
        axios
          .post('/lienVersDonnée', {
            currentLang: rootGetters['lang/getCurrentLang'],
            /*
          retourne status : "Etat deja traduit depuis la base"
        */
          })
          .then((response) => {
            // dans la reponse on doit avoir status qui changera la valeur actuelle.
            commit('setPrices', response.prices);
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },
  },
};
export default welcome;
